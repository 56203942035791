'use client';

import { forwardRef } from 'react';
import Link from 'next/link';
import type { LinkProps as NextLinkProps } from 'next/link';
import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xxl: true;
    '2xl': true;
  }
}

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as' | 'passHref' | 'onMouseEnter' | 'onClick' | 'onTouchStart'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

const LinkBehaviour = forwardRef<HTMLAnchorElement, LinkProps>(function LinkBehaviour(props, ref) {
  const { href, ...others } = props;
  return <Link href={href} ref={ref} {...others} />;
});

const extendedTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#5C058C',
        },
        secondary: {
          main: '#5C058C',
        },
      },
    },
  },
  typography: {
    fontFamily: ['var(--font-montserrat)', '"Helvetica Neue"', 'Arial'].join(','),
  },
  breakpoints: {
    values: {
      xxs: 300,
      xs: 480,
      sm: 768,
      md: 960,
      lg: 1200,
      xl: 1440,
      xxl: 1728,
      '2xl': 1920,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderBottom: '1px solid #DEE9F8',
            boxShadow: 'none',
            background: 'transparent',
            width: '100%',
            ':last-of-type': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
            '&:before': {
              content: 'none',
            },
          }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            color: '#202020',
            flexDirection: 'row-reverse',
            fontSize: '1.125rem',
            fontWeight: 500,
            paddingLeft: '1rem',
            '&.Mui-expanded': {
              minHeight: 'inherit',
            },
            '&.Mui-focusVisible': {
              backgroundColor: '#DEE9F8',
            },
          }),
        content: ({ theme }) =>
          theme.unstable_sx({
            '&.Mui-expanded': {
              margin: '12px 0 !important',
            },
          }),
        expandIconWrapper: ({ theme }) =>
          theme.unstable_sx({
            transition: 'none',
            '&.Mui-expanded': {
              transform: 'none',
            },
          }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            paddingLeft: '3rem',
          }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#60a5fa',
          }),
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontSize: '0.75rem',
          }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            fontSize: '1.125rem',
            fontWeight: 700,
            py: '0.5rem',
            px: '2.25rem',
            textTransform: 'none',
          }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehaviour,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '& .MuiDialog-paper': {
              borderRadius: '0.5rem',
              margin: '1rem',
              [theme.breakpoints.up('md')]: {
                margin: '2.5rem',
              },
              [theme.breakpoints.up('lg')]: {
                margin: '4rem',
              },
            },
          }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      } as Partial<MuiLinkProps> | undefined,
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 600,
          }),
      },
    },
  },
});

export default extendedTheme;
